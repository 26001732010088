@import "bootstrap/dist/css/bootstrap.min.css";

* {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  margin: 0;
  padding: 0;
  -webkit-tap-highlight-color: transparent;
}

:root {
  --white-text-blue: #3421ff;
  --dark-text-black: #1a191c;
  --text-default-white: #ffffff;
  --bg-footer-black: #000000;
}
::selection {
  background-color: var(--dark-text-black);
  color: var(--text-default-white);
}
html,
body {
  min-height: 100vh;
  scroll-behavior: smooth;
  flex-direction: column;
  align-items: stretch;
  display: flex;
}
main {
  flex-grow: 1;
  display: flex !important;
  justify-content: center;
  flex-direction: column;
}

main,
footer {
  flex-shrink: 0;
}
body {
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  line-height: 1.625rem;
  background-color: var(--text-default-white);
}
#root {
  height: 100vh;
  scroll-behavior: smooth;
  flex-direction: column;
  align-items: stretch;
  display: flex;
}

/* src/fonts.css */
@font-face {
  font-family: "Roboto";
  src: url("/public/font-family/Roboto-BlackItalic.woff2") format("woff2"),
    url("/public/font-family/Roboto-BlackItalic.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("/public/font-family/Roboto-Black.woff2") format("woff2"),
    url("/public/font-family/Roboto-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("/public/font-family/Roboto-Bold.woff2") format("woff2"),
    url("/public/font-family/Roboto-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("/public/font-family/Roboto-Italic.woff2") format("woff2"),
    url("/public/font-family/Roboto-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("/public/font-family/Roboto-BoldItalic.woff2") format("woff2"),
    url("/public/font-family/Roboto-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("/public/font-family/Roboto-Light.woff2") format("woff2"),
    url("/public/font-family/Roboto-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("/public/font-family/Roboto-LightItalic.woff2") format("woff2"),
    url("/public/font-family/Roboto-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("/public/font-family/Roboto-Thin.woff2") format("woff2"),
    url("/public/font-family/Roboto-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("/public/font-family/Roboto-ThinItalic.woff2") format("woff2"),
    url("/public/font-family/Roboto-ThinItalic.woff") format("woff");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("/public/font-family/Roboto-Medium.woff2") format("woff2"),
    url("/public/font-family/Roboto-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("/public/font-family/Roboto-Regular.woff2") format("woff2"),
    url("/public/font-family/Roboto-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("/public/font-family/Roboto-MediumItalic.woff2") format("woff2"),
    url("/public/font-family/Roboto-MediumItalic.woff ") format("woff");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto";
  line-height: 1;
}
h1 {
  font-size: 2.5rem;
}
h2 {
  font-size: 1.875rem;
}
h3 {
  font-size: 1.625rem;
}
h4 {
  font-size: 1.5rem;
}
h5 {
  font-size: 1.25rem;
}
h6 {
  font-size: 1.125rem;
}
*:focus {
  outline-color: var(--white-text-orange);
}

a {
  text-decoration: none;
}

a:hover {
  color: var(--gm-primary);
  text-decoration: none;
}

img {
  vertical-align: middle;
  max-width: 100%;
  height: auto;
  border: none;
}

iframe {
  max-width: 100%;
  border: none;
}

input,
textarea,
select,
button {
  font-family: "Roboto";
}
input:focus,
textarea:focus,
select:focus,
button:focus {
  outline: none;
}

li {
  list-style: none;
}
p {
  color: var(--dark-text-gray);
  font-size: 1rem;
  letter-spacing: 0.0313rem;
}

.container {
  margin: 0 auto;
  padding: 0 0.9375rem;
  max-width: 78.75rem;
}

.section_spacing_y {
  padding-block: 5.3125rem;
}
button {
  cursor: pointer;
}
input::placeholder {
  font-family: "Roboto";
}
table {
  border-collapse: collapse;
}
th,
td {
  padding: 8px 20px;
}

/* Layout Styles */

.video-background {
  position: fixed; /* Ensures the video covers the entire viewport */
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  z-index: -1; /* Ensures the video is behind other content */
}
.video-background-hidden {
  position: fixed; /* Ensures the video covers the entire viewport */
  top: 0;
  left: 0;
  display: none;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  z-index: -1; /* Ensures the video is behind other content */
}
.video-background::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 1; /* Places the overlay above the video */
}
.video-background-hidden::before {
  content: "";
  position: absolute;

  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2); /* Semi-transparent black overlay */
  z-index: 1; /* Places the overlay above the video */
}
.video-background-hidden video {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100vh;
  object-fit: cover;
  transform: translate(-50%, -50%);
  z-index: 0; /* Keeps the video below the overlay */
}
.video-background video {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100vh;
  object-fit: cover;
  transform: translate(-50%, -50%);
  z-index: 0; /* Keeps the video below the overlay */
}

.img-logo {
  text-align: center;
  padding-top: 30px;
}

.header-text {
  color: var(--text-default-white);
  font-size: clamp(2rem, 5vw, 3.7rem); /* Dynamic font size */
  font-weight: 500;
  line-height: 1.2; /* Adjust line-height for better readability */
  margin: 0;
}

.header-main {
  padding: 0 20px;
}

.notify-box {
  background-color: rgba(0, 0, 0, 0.2);
  text-align: center;
  width: fit-content;
  margin-top: 20px;
  margin-inline: auto;
  padding: 30px;
  border-radius: 12px;
}

.notify-title {
  color: var(--text-default-white);
  font-size: 20px;
  font-weight: 500;
}

.button-main {
  display: flex;
  justify-content: center;
  gap: 25px;
}

.brand-button > a {
  background-color: var(--white-text-blue);
  color: var(--text-default-white);
  padding-block: 10px;
  padding-inline: 30px;
  border-radius: 10px;
  font-size: 17px;
  font-weight: 400;
  text-decoration: none;
}

.footer-main {
  text-align: center;
  padding-bottom: 30px;
}
.footer-text {
  color: var(--text-default-white);
  font-size: 17px;
  font-weight: 400;
  margin: 0;
}
.module-Brand {
  background-color: #121212;
  padding: 20px;
  border-radius: 8px;
  width: 300px;
  margin: 0 auto;
  color: white;
}

.form-title {
  margin-bottom: 10px;
  font-size: 18px;
}

.form-label {
  display: block;
  margin-bottom: 5px;
  font-size: 14px;
  color: #ccc;
}

.form-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
  border: 1px solid #444;
  background-color: #1f1f1f;
  color: #ccc;
}

.form-button {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 4px;
  background-color: #2e00ff;
  color: white;
  font-size: 16px;
  cursor: pointer;
}

.form-button:hover {
  background-color: #3b00ff;
}

.form-message {
  margin-top: 10px;
  border-radius: 14px;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.hidden {
  display: none;
}

.success {
  background-color: #1f7a1f;
  padding: 15px;
}

.error {
  background-color: #b32020;
  padding: 15px;
}
.icon {
  width: 20px;
  height: 20px;
  vertical-align: middle;
  margin-right: 8px;
}

.custome-model .modal-content {
  background-color: var(--dark-text-black);
  color: #ffffff;
  border-radius: 10px;
}
.btn-close {
  margin-bottom: 20px !important;
}
.custome-header {
  padding-inline: 30px !important;
  padding-top: 30px !important;
  padding-bottom: 10px !important;
}
.border-main {
  border: 1px solid white;
  padding-inline: 10px;
}
.custome-body {
  padding: 30px !important;
}
.btn-close:focus:not(:focus-visible) {
  outline: none;
  box-shadow: none;
}
.btn-clr {
  background-color: var(--white-text-blue);
  border: none;
  padding-inline: 30px;
  padding-block: 10px;
  border-radius: 10px;
}
.header-titles {
  text-align: center;
  margin-bottom: 40px;
}
.form-input.error-form {
  border: 1px solid red;
}
.custome-input {
  border-radius: 10px;
}
.custome-button {
  border-radius: 10px;
}
.error-message {
  font-size: 0.875rem;
}
.costume-title {
  font-size: 24px !important;
}

@media (max-width: 500px) {
  .video-background-hidden {
    display: block;
  }
  .video-background {
    display: none;
  }
}
@media (max-width: 768px) {
  .header-text {
    font-size: 25px;
    line-height: 30px;
  }

  .header-description {
    font-size: 46px;
  }
  .button-main {
    flex-direction: column;
  }
  .header-main {
    padding: 0;
  }
  .notify-box {
    width: unset;
    margin: 0 auto;
  }
  .header-titles {
    margin-bottom: 30px;
  }

  .notify-box {
    padding: 20px;
  }
}
